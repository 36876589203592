@import "settings";

.row-profile {
  @include adaptive-value('padding', 40, 16, 1);
  @include adaptive-value('margin-inline', -40, 0, 1);
  @include adaptive-value('border-radius', 70, 17, 1);
  background-color: $main-white;
  display: flex;
  position: relative;
  align-items: center;
  gap: rem(15px);

  &__main {
    flex-grow: 1;
    display: grid;
    align-items: center;
    gap: rem(10px);
    grid-template-columns: 1fr;

    @include breakpoint('xl') {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  &__btn {
    @include adaptive-value('width', 54, 40, 1);
    aspect-ratio: 1;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: none;
  }
}
