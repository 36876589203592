@import "settings";

app-editor {
  border-radius: rem(20px);

  &.ng-invalid.ng-touched {
    quill-editor .ql-container.ql-snow {
      box-shadow: inset 0 0 0 rem(1px) $error;
    }
  }

  &:not(.ng-invalid.ng-touched):not(:focus-within) {
    quill-editor .ql-container.ql-snow:hover {
      box-shadow: inset 0 0 0 rem(1px) var(--clr-border-active);
    }
  }
}

quill-editor {
  --bg-input: #{$alabaster};
  --clr-shadow: #{$main-black};
  --bg-focus-input: #{$concrete};
  --clr-focus-shadow: #{$silver};
  --clr-border-active: #{$main-black};
  --clr-placeholder: #{$silver};
  --box-shadow: 4px 4px 16px #{rgba($main-black, .15)};
  --color-link: #{$yellow};
  --color-text: #{$main-black};

  &.black {
    --bg-input: #{$mine-shaft};
    --clr-shadow: #{$mine-shaft};
    --bg-focus-input: #{$mine-shaft-two};
    --clr-border-active: #{$main-white};
    --clr-focus-shadow: #{$gray-opacity};
    --clr-placeholder: #{$silver};
    --box-shadow: 4px 4px 16px transparent;
    --color-link: #{$yellow};
    --color-text: #{$main-white};
  }

  &.yellow {
    --bg-input: #{$kournikova};
    --clr-shadow: #{$main-black};
    --bg-focus-input: #{$kournikova};
    --clr-focus-shadow: #{$black-opacity};
    --clr-placeholder: #{$mine-shaft};
    --box-shadow: 4px 4px 16px #{rgba($main-black, .15)};
    --color-link: #{$main-gray};
    --color-text: #{$main-black};
  }

  position: relative;
  border-radius: inherit;

  &:focus-within {
    .ql-toolbar.ql-snow {
      transform: translate(-50%, -50%);
      opacity: 1;
      filter: blur(0);
      pointer-events: auto;
    }

    .ql-container.ql-snow {
      background-color: var(--bg-focus-input);
      box-shadow: inset 0 0 0 rem(1px) var(--clr-border-active), 0 0 0 rem(5px) var(--clr-focus-shadow);
    }
  }

  .ql-toolbar.ql-snow {
    position: absolute;
    left: 50%;
    top: 0;
    transform: translate(-50%, -20%);
    background: $main-white;
    z-index: 1;
    padding: 0;
    border-radius: 4px;
    border: none;
    opacity: 0;
    pointer-events: none;
    filter: blur(4px);
    transition-property: opacity, transform, filter;
    transition-duration: 404ms;
    transition-timing-function: ease-in-out;
    box-shadow: var(--box-shadow);

    .ql-formats {
      margin-right: 0;
    }
  }

  .ql-container.ql-snow {
    font-weight: 500;
    line-height: rem(20px);
    background-color: var(--bg-input);
    box-shadow: inset 0 0 rem(1px) var(--bg-input);
    border-radius: inherit;
    width: 100%;
    outline: none;
    transition: color 404ms, box-shadow 404ms;
    appearance: none;
    border: none;
    padding-top: rem(10px);
    padding-bottom: rem(10px);
  }

  .ql-editor {
    padding: rem(10px) rem(24px);
    font-size: rem(16px);
    display: flex;
    flex-direction: column;
    gap: rem(10px);

    &.ql-blank::before {
      font-style: normal;
      left: rem(24px);
      right: rem(24px);
      color: var(--clr-placeholder);
    }

    em {
      font-style: italic;
    }

    ul, ol {
      padding-left: 0;
    }

    a {
      color: var(--color-link);
    }
  }

  .ql-snow.ql-toolbar {
    button {
      &:hover {
        .ql-stroke, .ql-fill {
          transition: stroke 404ms ease-in-out;
          stroke: rgba($yellow, .9);
        }
      }

      &.ql-active {
        .ql-stroke, .ql-fill {
          transition: stroke 404ms ease-in-out;
          stroke: $yellow;
        }
      }
    }
  }

  .ql-snow .ql-tooltip {
    border-radius: rem(15px);
    background-color: $yellow;
    border: none;
    box-shadow: 0 0 rem(5px) var(--bg-input);

    input[type="text"] {
      padding: inherit;
      height: auto;
      padding-block: rem(10px);
      line-height: 1;
      min-height: rem(20px);
      border: none;
      box-shadow: none;
      color: $main-black;
      background-color: $main-white;

      &:focus {
        box-shadow: none;
        color: $main-black;
        background-color: $main-white;
      }
    }

    .ql-remove,
    .ql-action {
      color: $main-black;

      @media (hover: hover) {
        transition: opacity 404ms ease-in-out;

        &:hover {
          opacity: .7;
        }
      }
    }

    .ql-preview {
      color: $dove-gray;
    }

    .ql-action {
      &::after {
        border-right: rem(1px) solid $main-black;
      }
    }
  }
}
