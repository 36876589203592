@import "settings";

.password-wrapper {
  position: relative;

  .eye {
    position: absolute;
    right: rem(15px);
    top: 50%;
    transform: translateY(-50%);

    @media (hover: hover) {
      cursor: pointer;
      transition: opacity 404ms;

      &:hover {
        opacity: .6;
      }
    }
  }

  svg-icon svg {
    stroke: transparent;
  }
}
