@import "settings";

.profile-item {
  @include adaptive-value('border-radius', 70, 40, 1);
  width: 100%;
  display: grid;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    @include adaptive-value('padding-block', 60, 16, 1);
    @include adaptive-value('padding-left', 70, 16, 1);
    @include adaptive-value('padding-right', 30, 16, 1);
  }

  &__title {
    font-weight: 700;
    font-size: rem(18px);

    @media (hover: hover) {
      transition: opacity 404ms ease-in-out;
      cursor: pointer;

      &:hover {
        opacity: .6;
      }
    }
  }

  &__legend {
    position: absolute;
    top: calc(100% + #{rem(10px)});
    font-size: rem(14px);
    display: flex;
    align-items: center;
    font-weight: 500;
    white-space: nowrap;
  }

  .arrow-btn,
  .edit-btn {
    width: rem(35px);
    aspect-ratio: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }

  .edit-btn {
    box-shadow: inset 0 0 0 rem(1px) currentColor;
    transition: box-shadow 404ms;

    @media (hover: hover) {
      cursor: pointer;

      &:hover {
        box-shadow: inset 0 0 0 rem(1px) $yellow;
      }
    }
  }

  &__content {
    @include adaptive-value('padding-top', 30, 16, 1);
    @include adaptive-value('padding-bottom', 60, 16, 1);
    @include adaptive-value('padding-inline', 120, 16, 1);
  }

  &__main {
    &.is-open {
      animation-name: hide;
      animation-duration: 404ms;
      animation-timing-function: ease-in-out;
      animation-fill-mode: forwards;
    }

    &.is-closed {
      overflow: hidden;
    }
  }

  &__action {
    display: flex;
    justify-content: flex-end;

    &--top {
      @include adaptive-value('margin-top', 60, 30, 1);
    }

    &--center {
      justify-content: center;
    }

    button {
      @include breakpoint('md') {
        max-width: rem(240px);
      }
    }
  }
}

@keyframes hide {
  0%, 99% {
    overflow: hidden;
  }
  100% {
    overflow: visible;
  }
}
