@import "settings";

.search-item {
  display: grid;
  padding-block: rem(10px);
  padding-right: rem(7px);
  padding-left: rem(35px);
  grid-template-columns: 1fr auto;
  align-items: center;
  gap: rem(50px);
  border-radius: 100vmax;
  box-shadow: inset 0 0 0 rem(1px) currentColor;
  text-align: left;

  @include breakpoint('lg') {
    padding-left: rem(60px);
    min-width: rem(400px);
  }

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    aspect-ratio: 1;
    border-radius: 50%;
    width: rem(65px);

    @include breakpoint('xl') {
      width: rem(90px);
    }

    svg-icon {
      width: 50%;
      transition: scale 404ms ease-in-out;

      svg {
        margin: auto;
      }
    }
  }

  @media (hover: hover) {
    &:hover {
      svg-icon {
        scale: 1.1;
      }
    }
  }
}
