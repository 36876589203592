@import "settings";

.logo-wrapper {
  --logo-height: #{rem(50px)};
  --img-height: 60%;
  --pd-inline: #{rem(15px)};

  &--small {
    --logo-height: #{rem(40px)};
    --img-height: 45%;
    --pd-inline: #{rem(14px)};
  }

  &--default {
    --logo-height: #{rem(70px)};
    --img-height: 45%;
    --pd-inline: #{rem(25px)};
  }

  &--big {
    --logo-height: #{rem(60px)};
    --pd-inline: #{rem(20px)};

    @include breakpoint('xl') {
      --img-height: 40%;
      --logo-height: #{rem(110px)};
      --pd-inline: #{rem(45px)};
    }
  }

  height: var(--logo-height);
  border-radius: 50vmax;
  display: inline-flex;
  justify-content: center;
  flex: none;
  align-items: center;
  padding-inline: var(--pd-inline);

  img {
    height: var(--img-height);
  }
}
