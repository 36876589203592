@import "settings";

.tab-items {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: rem(10px);
  position: relative;

  &__underline {
    transition: width 404ms ease-in-out, left 404ms ease-in-out;
    position: absolute;
    top: 100%;
    height: rem(6px);
    width: auto;
    left: 0;
    background-color: currentColor;
    transform: translateY(-50%);
    border-radius: rem(10px);
  }

  @include breakpoint('lg') {
    align-self: flex-start;
  }

  &::after {
    content: '';
    position: absolute;
    top: 100%;
    width: 100%;
    height: rem(1px);
    background-color: currentColor;
  }

  .tab {
    @include adaptive-value('font-size', 16, 14, 1);
    @include adaptive-value('padding-inline', 10, 5, 1);
    @include adaptive-value('gap', 10, 5, 1);
    padding-bottom: rem(10px);
    position: relative;
    display: flex;
    align-items: center;

    @media (hover: hover) {
      cursor: pointer;
      transition: opacity 404ms ease-in-out;

      &:hover {
        opacity: .6;
      }
    }

    &::after {
      content: '';
      position: absolute;
      top: 100%;
      width: 100%;
      height: rem(6px);
      background-color: currentColor;
      opacity: 0;
      left: 0;
      transform: translateY(-50%);
      border-radius: rem(10px);
    }

    &__count {
      font-size: rem(7px);
      width: rem(14px);
      aspect-ratio: 1;
      display: grid;
      place-content: center;
      border-radius: 50%;
      background-color: $yellow;
    }
  }
}
