@import "settings";

.scroll-yellow {
  --colorTrack: #{$main-black};
  --hiddenTrackColor: #{$yellow};
  --colorThumb: #{$main-white};

  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 0 rem(3px) var(--hiddenTrackColor), inset 0 0 0 rem(5px) var(--colorThumb);
    border-radius: rem(10px);
    background-color: transparent;
  }

  ::-webkit-scrollbar {
    width: rem(8px);
    background-color: var(--colorThumb);
  }

  ::-webkit-scrollbar-thumb {
    border-radius: rem(10px);
    background-color: var(--colorThumb);
  }
}

.scroll {
  --hiddenTrackColor: theme('colors.cod-gray-two');
  --colorThumb: theme('colors.yellow');

  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 0 rem(3px) var(--hiddenTrackColor), inset 0 0 0 rem(5px) var(--colorThumb);
    border-radius: rem(10px);
    background-color: transparent;
    margin-block: 90px;
  }

  ::-webkit-scrollbar {
    width: rem(8px);
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: rem(10px);
    background-color: var(--colorThumb);
  }
}

.space-wrapper-scroll {
  margin-right: rem(-10px);

  & > * {
    padding-right: rem(10px);
  }
}
