@import "settings";

html {
  font-size: $global-font-size;
  color: $main-black;
  font-weight: 700;
}

app-root {
  height: 100%;
  display: flex;
  flex-direction: column;
}

app-component {
  height: 100%;
  display: flex;
  flex-direction: column;
}

app-login-page {
  @apply bg-white dark:bg-black;
}

a {
  text-decoration: none;
  color: currentColor;
}

.overflow-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.modal-open {
  overflow-y: hidden;
}

pre {
  background: #efefef;
  padding: rem(10px);
  font-size: rem(14px);
}

.container-fluid {
  width: 100%;
  padding-left: rem(15px);
  padding-right: rem(15px);

  @include breakpoint('xl') {
    padding-left: rem(50px);
    padding-right: rem(50px);
  }
}

.content-card {
  height: 100%;
  position: relative;
  border-radius: rem(75px);

  @include breakpoint('lg') {
    box-shadow: rem(7px) rem(7px) rem(100px) currentColor;
  }
}

.scroll-wrapper {
  @include breakpoint('lg') {
    position: relative;
    overflow: hidden;
    height: 100%;

    & > * {
      position: absolute;
      width: 100%;
      inset: 0;
      overflow-y: auto;
      overflow-x: hidden;
      align-items: flex-start;
    }
  }
}

.container-form {
  .ng-scroll-viewport,
  .ng-scroll-content {
    width: 100%;
  }

  .ng-scrollbar-wrapper[deactivated=false][dir=ltr] {
    left: rem(-16px);
    right: rem(-16px);

    form {
      padding-inline: rem(16px);
      padding-block: rem(8px);
    }
  }

  .ng-scrollbar-wrapper[deactivated=false][dir=ltr] > scrollbar-y.scrollbar-control {
    right: 0;
    height: 60%;
    top: 50%;
    transform: translateY(-50%);
    width: auto;

    @include breakpoint('lg') {
      right: rem(-20px);
    }

    .ng-scrollbar-thumb {
      background-color: $yellow;
    }

    .ng-scrollbar-track {
      @apply bg-white dark:bg-black;
    }
  }
}

.app-container .ng-scrollbar-wrapper[deactivated=false][dir=ltr] > scrollbar-y.scrollbar-control {
  right: rem(-14px);
  height: 40vh;
  top: 50%;
  transform: translateY(-50%);
  width: auto;

  .ng-scrollbar-thumb {
    background-color: $yellow;
  }

  .ng-scrollbar-track {
    background-color: $main-black;
  }
}

ng-particles {
  position: absolute;
  inset: 0;
  pointer-events: none;
  z-index: 50;
}

.filter-blur {
  position: relative;
  filter: blur(rem(10px));
  pointer-events: none;
  user-select: none;

  &::after {
    content: '';
    inset: 0;
    position: absolute;
    background-color: rgba($main-white, .5);
    backdrop-filter: blur(rem(10px));
  }
}

[hasanimation] {
  @include breakpoint('lg') {
    overflow: hidden !important;
  }
}

[applineclamp] {
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.event-columns {
  display: grid;

  &--col-3 {
    grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
  }
}
