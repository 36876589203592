@import 'settings';

$select-shadow: rgba($main-black, 0.15);

.select {
  --clr-arrow: #{$main-black};
  --clr-border-active: #{$main-black};
  --clr-text-input: #{$main-black};
  --clr-text-dropdown: #{$main-black};
  --clr-dropdown-shadow: 0 4px 21px #{$select-shadow};
  --clr-border: #{$main-black};
  --clr-focused: #{$silver};
  --bg-select-default: #{$alabaster};
  --bg-select-active: #{$alabaster};
  --bg-dropdown: #{$main-white};
  --hover-option: rgba(217, 217, 217, 0.2);

  &.dark {
    --clr-arrow: #{$main-white};
    --clr-border-active: #{$main-white};
    --clr-text-input: #{$main-white};
    --clr-text-dropdown: #{$main-white};
    --clr-dropdown-shadow: 0 4px 21px #{$select-shadow};
    --clr-border: #{$main-white};
    --bg-select-default: #{$mine-shaft};
    --clr-focused: #{$gray-opacity};
    --bg-select-active: #{$mine};
    --bg-dropdown: #{$mine};
    --hover-option: #{$gray-opacity};
  }

  &.yellow {
    --clr-arrow: #{$main-black};
    --clr-border-active: #{$main-black};
    --clr-text-input: #{$main-black};
    --clr-text-dropdown: #{$main-black};
    --clr-dropdown-shadow: 0 4px 21px #{$select-shadow};
    --bg-select-default: #{rgba(255, 255, 255, 0.2)};
    --bg-select-active: #{$kournikova};
    --clr-focused: #{$black-opacity};
    --bg-dropdown: #{$kournikova};
    --hover-option: rgba(255, 255, 255, 0.4);
  }

  &.ng-invalid.ng-touched {
    box-shadow: inset 0 0 0 rem(1px) $error;
  }

  font-size: rem(16px);
  background-color: var(--bg-select-default);
  border-radius: rem(30px);
  padding: rem(10px) rem(24px);
  font-weight: 500;
  width: 100%;
  transition: background 400ms, border 400ms, box-shadow 404ms;
  box-shadow: inset 0 0 0 rem(1px) transparent;

  &.ng-select-focused {
    background-color: var(--bg-select-active);
    box-shadow: inset 0 0 0 rem(1px) var(--clr-border-active), 0 0 0 rem(7px) var(--clr-focused);
  }

  &:not(.ng-select-disabled):hover,
  &.ng-select-opened {
    background-color: var(--bg-select-active);
    box-shadow: inset 0 0 0 rem(1px) var(--clr-border-active);
  }

  &.ng-select-disabled {
    opacity: .5;
    cursor: not-allowed;
  }

  &.ng-select {
    min-height: rem(50px);
    display: flex;
    align-items: center;

    .ng-select-container {
      overflow: visible;
      cursor: pointer;
      min-height: rem(20px);
      line-height: rem(20px);

      .ng-input {
        display: flex;
        align-items: center;
        height: rem(20px);

        input {
          height: rem(20px);
          line-height: 1;
          border-radius: 0;
        }
      }
    }

    .ng-option {
      transition: background-color 404ms;
      padding: rem(10px) rem(30px);

      &.ng-option-disabled {
        opacity: .3;
      }

      &.ng-option-selected,
      &.ng-option-marked,
      &:hover {
        background-color: var(--hover-option);
      }
    }
  }

  .ng-arrow-wrapper {
    align-self: center;
    transform: scale(1);
    transition: transform 404ms;
    width: 0;
    height: 0;
    border-left: rem(5px) solid transparent;
    border-right: rem(5px) solid transparent;
    border-top: rem(7px) solid var(--clr-arrow);
  }

  &.ng-select-opened .ng-arrow-wrapper {
    transform: scale(-1);
  }

  .ng-dropdown-panel {
    left: 0;
    top: calc(100% + #{rem(10px)});
    padding-block: rem(10px);
    background-color: var(--bg-dropdown);
    border-radius: rem(18px);
    border: rem(1px) solid var(--clr-border);
    box-shadow: var(--clr-dropdown-shadow);
    font-weight: 500;
    color: var(--clr-text-dropdown);
    transition: opacity 404ms ease-in-out;

    &:after {
      content: "";
      display: block;
      width: 100%;
      height: 20px;
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
    }

    .ng-dropdown-panel-items {
      max-height: rem(360px);
      border-radius: rem(3px) / rem(8px);
    }
  }
}
