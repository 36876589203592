@import "settings";

.ng-select.language {
  --width-arrow: #{rem(6px)};

  display: flex;
  min-width: rem(40px);

  .ng-select-container {
    overflow: visible;
    display: flex;
    align-items: center;
    gap: rem(6px);
    transition: color 404ms ease-in-out;
    cursor: pointer;
  }

  .ng-value-label {
    font-size: rem(18px);
    font-weight: 700;
  }

  .ng-dropdown-panel {
    @apply bg-yellow shadow-black lg:dark:text-black;
    padding-block: rem(15px);
    width: rem(70px);
    box-shadow: 15px 10px 20px rgb(#{var(--tw-shadow)} / .15);
    border: rem(1px) solid var(--tw-shadow-color);
    border-radius: rem(15px);
    animation: showLanguage 404ms forwards;
    left: calc(100% - (var(--width-arrow) / 2));
    transform: translateX(-50%);

    &::after {
      @apply bg-yellow;
      content: "";
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, -50%) rotate(45deg);
      width: var(--width-arrow);
      aspect-ratio: 1;
      display: flex;
      border-radius: 2px;
      border: 1px solid;
      border-bottom-color: transparent;
      border-right-color: transparent;
    }
  }

  .ng-option {
    @include adaptive-value('font-size', 14, 12, 1);
    text-align: center;
    padding-block: rem(4px);
    transition: background-color 404ms ease-in-out;

    &.ng-option-marked {
      @apply bg-shaft/10;
    }
  }

  .ng-arrow-wrapper {
    display: flex;
    align-items: center;

    .ng-arrow {
      background-color: currentColor;
      width: var(--width-arrow);
      height: calc(var(--width-arrow) - rem(2px));
      clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
      aspect-ratio: 1;
      transition: clip-path 404ms ease-in-out;
    }
  }

  &.ng-select-opened {
    .ng-select-container {
      @apply text-black/30 lg:text-black/30 lg:dark:text-white/30;
    }

    .ng-arrow {
      clip-path: polygon(50% 100%, 0 0, 100% 0);
    }
  }
}

@keyframes showLanguage {
  from {
    opacity: 0;
    top: calc(100% + #{rem(20px)});
    filter: blur(3px);
  }
  to {
    opacity: 1;
    top: calc(100% + #{rem(10px)});
    filter: blur(1);
  }
}
