$fonts-path: "./../../assets/fonts/" !default;
$global-font-size: 16px;
$container-max-width: 1920px;
$container-small: 1440px;

$breakpoints: (
  'sm': 576px,
  'md': 768px,
  'lg': 992px,
  'xl': 1280px,
  'xxl': 1440px,
  'xlg': 1920px,
) !default;

@import "helpers/mixins";
@import "helpers/functions";
@import "helpers/colors";
