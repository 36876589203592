@import "~swiper/swiper.min.css";
@import "~swiper/modules/free-mode/free-mode.min.css";
@import "~swiper/modules/scrollbar/scrollbar.min.css";
@import "~swiper/modules/effect-fade/effect-fade.min.css";
@import 'quill/dist/quill.snow.css';

@import "styles/settings";
@import "styles/base";
@import "styles/typography";
@import "styles/fonts";

@import "atoms/hover-link";
@import "atoms/select-language";
@import "atoms/button";
@import "atoms/btn-preview-profile";
@import "atoms/registration-info-card";
@import "atoms/swiper-pagination";
@import "atoms/select";
@import "atoms/select-checkbox";
@import "atoms/file-upload";
@import "atoms/label-status";
@import "atoms/arrow-icon";
@import "atoms/progress-profile";
@import "atoms/scroll-page";
@import "atoms/input";
@import "atoms/tag";
@import "atoms/date-item";
@import "atoms/tooltip";
@import "atoms/logo-wrapper";
@import "atoms/info-label";
@import "atoms/language";

@import "form-elements/form-elements";
@import "form-elements/password-wrapper";

@import "component/custom-modal";
@import "component/block-registration";
@import "component/professional-info-item";
@import "component/cropper";
@import "component/info-item";
@import "component/block-info-accordion";
@import "component/product-item";
@import "component/profile-item";
@import "component/row-profile";
@import "component/block-salary";
@import "component/company-banner";
@import "component/tab-items";
@import "component/ellipse-menu";
@import "component/quill-editor";
@import "component/quill-content";
@import "component/search-item";
@import "component/banner-tutorial";
@import "component/empty-jobs-list";
@import "component/block-comment";

@import "styles/typography";

