@import "settings";

.block-salary {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: rem(25px);
  border-radius: rem(70px);

  @include breakpoint('md') {
    flex-direction: row;
    justify-content: center;
  }

  &__price {
    padding-inline: rem(40px);
    padding-block: rem(12px);
    box-shadow: inset 0 0 0 rem(1px) currentColor;
    font-size: rem(18px);
    border-radius: 30vmax;
    text-align: center;
    white-space: nowrap;
  }

  &__options {
    display: flex;
    gap: rem(30px);
    position: relative;
    padding-top: rem(25px);
    margin-top: rem(25px);

    &::after {
      content: '+';
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, -50%);

      @include breakpoint('md') {
        content: none;
      }
    }

    @include breakpoint('md') {
      padding-top: 0;
      margin-top: 0;
      gap: 0;
    }
  }

  &__option {
    font-size: rem(14px);
    display: flex;
    flex: none;
    align-items: center;
    gap: rem(10px);

    @include breakpoint('md') {
      flex-direction: row;
      padding-top: 0;
      margin-top: 0;
      padding-left: rem(15px);
      margin-left: rem(15px);
      position: relative;
    }

    &::after {
      @include breakpoint('md') {
        content: '+';
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(-50%, -50%);
      }
    }
  }

  &--not-salary {
    padding-top: 0;
    margin-top: 0;

    &::after {
      content: none;
    }
  }

  &--not-salary & {
    &__option:first-of-type {
      padding-left: 0;
      margin-left: 0;

      &::after {
        content: none;
      }
    }
  }
}
