@import "settings";

.banner-tutorial {
  font-size: rem(17px);
  background-color: theme('colors.yellow');
  color: theme('colors.black');
  border-radius: rem(10px);
  padding: rem(15px);
  display: flex;
  flex-direction: column;
  gap: 15px;

  @include breakpoint('lg') {
    padding: rem(8px);
    flex-direction: row;
  }

  &__picture {
    display: grid;
    place-items: center;
    aspect-ratio: 1.4;
    border-radius: rem(13px);
    overflow: hidden;

    @include breakpoint('lg') {
      flex: none;
      aspect-ratio: 1;
      width: rem(95px);
    }

    img {
      filter: grayscale(1);
    }
  }

  &__main {
    display: grid;
    grid-template-columns: 1fr auto;
    gap: rem(15px);
    align-items: center;
    padding-left: rem(20px);
    flex-grow: 1;
  }

  &__title {
    max-width: rem(280px);
  }

  &__icon {
    width: rem(65px);
    aspect-ratio: 1;
    display: grid;
    place-items: center;
    border-radius: 50%;
    box-shadow: inset 0 0 0 rem(1px) currentColor;

    @include breakpoint('xl') {
      width: rem(90px);
    }

    svg-icon {
      width: 50%;
      transition: scale 404ms ease-in-out;

      svg {
        margin: auto;
      }
    }
  }

  @media (hover: hover) {
    transition: background-color 404ms ease-in-out;

    &:hover {
      background-color: theme('colors.yellow' / .8);
    }

    &:hover & {
      &__icon svg-icon {
        scale: 1.1;
      }
    }
  }
}
