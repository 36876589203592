@import "settings";

input[type="text"],
input[type="number"],
input[type="url"],
input[type="email"],
input[type="password"] {
  text-overflow: ellipsis;
  overflow: hidden;
}

input[type="text"],
input[type="number"],
input[type="url"],
input[type="email"],
input[type="password"],
textarea {
  --bg-input: #{$alabaster};
  --clr-shadow: #{$main-black};
  --bg-focus-input: #{$concrete};
  --clr-focus-shadow: #{$silver};

  &.white {
    --bg-input: #{$alabaster};
    --clr-shadow: #{$main-black};
    --bg-focus-input: #{$concrete};
    --clr-focus-shadow: #{$silver};
  }

  &.black {
    --bg-input: #{$mine-shaft};
    --clr-shadow: #{$mine-shaft};
    --bg-focus-input: #{$mine-shaft-two};
    --clr-focus-shadow: #{$gray-opacity};
  }

  &.yellow {
    --bg-input: #{$kournikova};
    --clr-shadow: #{$main-black};
    --bg-focus-input: #{$kournikova};
    --clr-focus-shadow: #{$black-opacity};
  }

  font-weight: 500;
  padding: rem(10px) rem(24px);
  line-height: rem(20px);
  min-height: rem(50px);
  background-color: var(--bg-input);
  box-shadow: inset 0 0 rem(1px) var(--bg-input);
  border-radius: 100vmax;
  width: 100%;
  outline: none;
  transition: color 404ms, box-shadow 404ms;
  appearance: none;

  &:not(.ng-invalid.ng-touched):focus {
    background-color: var(--bg-focus-input);
    box-shadow: inset 0 0 0 rem(1px) var(--clr-shadow), 0 0 0 rem(5px) var(--clr-focus-shadow);
  }

  &:hover {
    box-shadow: inset 0 0 0 rem(1px) var(--clr-shadow);
  }

  &[disabled] {
    cursor: not-allowed !important;
    opacity: .5;
  }
}

textarea {
  border-radius: rem(20px);
}

[data-mode="dark"] {
  input[type="text"],
  input[type="number"],
  input[type="email"],
  input[type="url"],
  input[type="password"],
  textarea {
    &:not(.yellow, .white) {
      background-color: $mine-shaft;
      box-shadow: inset 0 0 0 rem(1px) $mine-shaft;

      &:focus {
        background-color: $mine-shaft-two;
        box-shadow: inset 0 0 0 rem(1px) $main-white, 0 0 0 rem(5px) $gray-opacity;
      }
    }
  }
}
