@import "settings";

.registration-info-card {
  text-align: center;
  display: grid;
  background: $yellow;
  gap: 0.625rem;
  border-radius: 3.125rem;
  min-height: clamp(280px, 35vh, 420px);
  grid-template-rows: 65% auto;
  place-items: center;
  padding: rem(25px);

  svg-icon {
    width: rem(42px);
    aspect-ratio: 1;
  }

  &__text {
    place-self: normal;
    padding-left: 15%;
    padding-right: 15%;
  }
}
