@tailwind base;
@tailwind utilities;

@layer base {
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  * {
    margin: 0;
    padding: 0;
    font: inherit;
  }

  ul[role='list'],
  ol[role='list'] {
    list-style: none;
  }

  html:focus-within {
    scroll-behavior: smooth;
  }

  html {
    display: flex;
  }

  html,
  body {
    min-height: 100%;
  }

  body {
    text-rendering: optimizeSpeed;
    line-height: 1.5;
    overflow-x: hidden;
    flex-grow: 1;
  }

  a:not([class]) {
    text-decoration-skip-ink: auto;
  }

  img,
  picture,
  svg {
    max-width: 100%;
    display: block;
    height: auto;
  }

  svg {
    fill: currentColor;
    transition: fill 404ms, stroke 404ms;
  }

  button {
    outline: none;
  }

  @media (prefers-reduced-motion: reduce) {
    html:focus-within {
      scroll-behavior: auto;
    }

    *,
    *::before,
    *::after {
      animation-duration: 0.01ms !important;
      animation-iteration-count: 1 !important;
      transition-duration: 0.01ms !important;
      scroll-behavior: auto !important;
    }
  }
}

@tailwind components;
