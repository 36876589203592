@import "settings";

.date-item {
  display: flex;
  flex-direction: column;

  &__item {
    display: flex;
    gap: rem(7px);
    padding: rem(10px);
    border-radius: rem(10px);
    box-shadow: inset 0 0 0 rem(1px) currentColor;

    span {
      min-width: rem(70px);
      font-weight: 600;
      text-align: center;
      padding: rem(6px);
      border-radius: rem(7px);
    }
  }

  &--to {
    padding-left: rem(20px);
    margin-left: rem(20px);
    position: relative;

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      left: 0;
      width: rem(10px);
      height: rem(1px);
      background-color: currentColor;
    }
  }
}
