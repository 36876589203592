@import "settings";

.empty-jobs-list {
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  svg-icon {
    max-width: rem(160px);
    justify-content: center;
  }

  p {
    font-size: 18px;
    opacity: .5;
  }

  button {
    width: auto;
    margin-top: rem(12px);
  }
}
