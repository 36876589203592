@import "settings";

.info-label-btn {
  width: 100%;
  aspect-ratio: 1;
  transition: color 404ms ease-in-out;
  cursor: pointer;

  &:hover,
  &.active {
    color: $yellow;
  }
}
