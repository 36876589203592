@import "settings";

popper-content {
  .ngxp__container {
    font-size: rem(10px);
    padding: rem(10px) rem(10px);
    border-radius: rem(20px);
    width: rem(150px);
    background-color: $yellow;
    color: $main-black;
    box-shadow: 0 rem(4px) rem(24px) rgba($main-black, .09);
    text-align: center;
    border: none;
    user-select: none;
    font-weight: 500;

    &.no-wrap {
      width: auto;
      white-space: nowrap;
    }

    & > .ngxp__arrow:before {
      background-color: $yellow;
    }

    &:not([data-popper-placement]) {
      opacity: 0 !important;
    }
  }
}
