@import "settings";

.product-item {
  @include adaptive-value('border-radius', 46, 24, 1);
  @include adaptive-value('padding-inline', 50, 16, 1);
  @include adaptive-value('padding-block', 50, 16, 1);
  @include adaptive-value('gap', 30, 20, 1);
  box-shadow: inset 0 0 0 rem(1px) $main-white;
  display: flex;
  flex-direction: column;

  &__logo {
    height: rem(70px);
    border-radius: 50vmax;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-inline: rem(20px);
    align-self: flex-start;

    img {
      max-height: rem(30px);
    }
  }
}
