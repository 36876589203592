@import 'settings';

.ng-select.language-select {
  text-transform: uppercase;
  cursor: pointer;
  opacity: 1;
  transition: opacity 404ms;
  font-size: rem(14px);
  line-height: 1.4;
  display: flex;

  .ng-value-label {
    color: currentColor;
  }

  .ng-arrow-wrapper {
    width: rem(10px);
    height: rem(10px);
    position: relative;
    display: flex;
    margin-left: rem(5px);

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0;
      width: 0;
      height: 0;
      border-left: rem(5px) solid transparent;
      border-right: rem(5px) solid transparent;
      border-top: rem(8px) solid currentColor;
      transition: all 400ms;
    }
  }

  &.ng-select-opened .ng-arrow-wrapper::after {
    transform: translateY(-50%) scale(-1);
    border-top: rem(8px) solid currentColor;
  }

  .ng-select-container {
    color: currentColor;
    border: none;
    align-items: center;
  }

  .ng-dropdown-panel {
    width: auto;
    box-shadow: rem(0 4px 24px 1px) rgba($main-black, 0.09);
    border-radius: rem(16px);
    border: rem(1px) solid transparent;
    top: calc(100% + #{rem(15px)});
    left: 50%;
    transform: translateX(-50%);
    background: white;
    color: $main-black;

    .scroll-host {
      margin: rem(10px 5px);

      @include breakpoint('xl') {
        margin: rem(15px 10px);
      }

      .ng-option {
        padding: rem(10px 15px);
        border-radius: rem(50px);
        font-size: rem(14px);
        color: currentColor;
        transition: all 400ms;

        &.ng-option-disabled {
          opacity: .3;
        }
      }

      .ng-option-marked {
        background-color: rgba($main-black, 20%);
      }
    }
  }

  .select.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
    width: calc(100% - #{rem(15px)});
  }
}
