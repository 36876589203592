@import "settings";

.block-comment {
  display: grid;
  grid-template-rows: 1fr auto;
  gap: rem(20px);
  height: 100%;

  &__list {
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-right: rem(15px);
    margin-right: rem(-15px);
    position: relative;
    min-height: rem(310px);

    &::after {
      content: none;
      position: absolute;
      width: 100%;
      left: 0;
      bottom: 0;
      height: rem(20px);
      background: linear-gradient(-360deg, theme('colors.white'), transparent);

      @include breakpoint('lg') {
        content: '';
      }
    }
  }

  &__editor {
    display: flex;
    flex-direction: column;
    gap: rem(30px);
  }

  .scroll {
    --hiddenTrackColor: theme('colors.white');
    --colorThumb: theme('colors.mine-shaft');
  }
}

.commend-item {
  @apply bg-alabaster;
  display: flex;
  flex-direction: column;
  gap: rem(10px);
  padding: rem(20px);
  border-radius: rem(20px);

  &__avatar {
    overflow: hidden;
    border-radius: 50%;
    aspect-ratio: 1;
    background: theme('colors.white');
    display: flex;
  }

  &__header {
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    gap: rem(15px);
  }

  &__user {
    display: grid;
    grid-template-columns: rem(40px) 1fr;
    align-items: center;
    gap: rem(10px);
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: rem(10px);
    font-size: 14px;
    font-weight: 400;
  }
}
