@import "settings";

.custom-modal {
  position: fixed;
  width: 100vw;
  inset: 0;
  pointer-events: auto;
  transition: opacity 400ms linear;
  will-change: opacity;
  opacity: 0;
  display: grid !important;
  place-content: center;
  z-index: 50;
  overflow: hidden;

  & > * {
    overflow: auto;
    padding: rem(16px);
    width: 100vw;
    scroll-behavior: smooth;

    @include breakpoint('lg') {
      min-height: 100vh;
      display: flex;
    }
  }

  &.shadow {
    &::after {
      content: '';
      inset: 0;
      background-color: $main-black;
      opacity: .8;
      position: absolute;
    }
  }

  @media (hover: hover) {
    cursor: pointer;
  }

  &--fade-in {
    opacity: 1;
  }

  &.error {
    background: transparent;
  }
}

.modal-form {
  @include adaptive-value('border-radius', 70, 40, 1);
  @include adaptive-value('padding-block', 70, 32, 1);
  @include adaptive-value('padding-inline', 80, 32, 1);
  position: relative;
  z-index: 1;
  background-color: $yellow;
  width: calc(100vw - #{rem(32px)});
  max-width: rem(1120px);
  display: flex;
  flex-direction: column;
  cursor: auto;
  margin-inline: auto;

  @include breakpoint('lg') {
    margin-block: auto;
  }

  &--small-py {
    @include adaptive-value('padding-block', 40, 32, 1);
  }

  &--small {
    max-width: rem(650px);
  }

  &--big {
    max-width: none;

    @include breakpoint('lg') {
      min-height: 100%;
    }
  }

  &__icon {
    display: flex;
    flex-direction: column;
    align-self: center;
    width: rem(70px);
    aspect-ratio: 1;
  }

  &__close {
    @include adaptive-value('right', 60, 32, 1);
    @include adaptive-value('top', 60, 20, 1);
    width: rem(40px);
    aspect-ratio: 1;
    border-radius: 50%;
    background-color: $main-white;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
  }

  &__title {
    @include adaptive-value('margin-bottom', 60, 40, 1);
    font-size: rem(18px);
    padding-right: rem(60px);
    position: relative;
  }

  &__legend {
    font-size: rem(14px);
    position: absolute;
    top: calc(100% + #{rem(5px)});
    white-space: nowrap;
  }

  &__wrapper {
    display: grid;
    gap: rem(32px);
    align-items: flex-start;
  }

  &__action {
    margin-top: rem(20px);

    button {
      @include breakpoint('md') {
        max-width: rem(240px);
      }
    }
  }

  &__chat {
    display: grid;
    gap: rem(15px);
    align-items: flex-start;

    @include breakpoint('lg') {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: auto 1fr;
      height: 100%;
    }

    .tab-items {
      @include breakpoint('lg') {
        grid-column: 1 / -1;
      }
    }
  }
}
