@import "settings";

.select-checkbox {
  display: grid;
  grid-template-columns: rem(15px) 1fr;
  align-items: center;
  gap: rem(10px);
  font-weight: 500;
  line-height: 1;

  &__checkbox {
    aspect-ratio: 1;
    box-shadow: inset 0 0 0 rem(1px) currentColor;
    border-radius: rem(3px);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: box-shadow 300ms;

    svg-icon {
      width: 70%;
      height: 70%;
      display: flex;
      opacity: 0;
      transition: opacity 404ms 100ms;
    }
  }

  &__label {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &.active {
    font-weight: 700;
  }

  &.active & {
    &__checkbox {
      box-shadow: inset 0 0 0 rem(10px) currentColor;

      svg-icon {
        opacity: 1;
      }
    }
  }
}
