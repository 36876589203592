@import "settings";

.professional-info-item {
  --shadow-item: theme('colors.black' / .15);

  &.dark {
    --shadow-item: theme('colors.alabaster' / .15);
  }

  border-radius: rem(40px);
  display: grid;
  grid-template-columns: 1fr auto;
  padding: rem(20px) rem(25px);
  gap: rem(8px);
  background: white;
  justify-content: space-between;
  align-items: center;
  min-height: rem(110px);
  width: 100%;
  transition: box-shadow 404ms;
  position: relative;
  z-index: 1;
  overflow: hidden;
  cursor: pointer;

  @include breakpoint('md') {
    grid-template-columns: 1fr auto;
  }

  @include breakpoint('lg') {
    border-radius: rem(60px);
    padding: rem(40px);
  }

  @include breakpoint('xxl') {
    display: flex;
    flex-direction: column;
    padding: rem(80px) rem(25px) rem(25px);
    max-width: rem(300px);
    min-height: rem(400px);
  }

  &--two-col {
    @include breakpoint('xxl') {
      max-width: rem(380px);
    }
  }

  &__icon {
    width: rem(24px);
    flex: none;

    @include breakpoint('xxl') {
      width: rem(42px);
      position: absolute;
      top: 45%;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &:hover {
    box-shadow: rem(18px) rem(14px) rem(47px) var(--shadow-item);
  }

  &__progress {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;
    width: var(--progress);
    height: 100%;
    background-color: $yellow;
    border-radius: inherit;
    transition: height 404ms;

    @include breakpoint('xxl') {
      width: 100%;
      height: var(--progress);
    }
  }

  &__name {
    font-size: rem(13px);
  }

  &__link {
    padding-block: rem(18px);
    font-size: rem(16px);
    text-align: center;
    grid-row: 3 / -1;
    grid-column-start: 2;

    @include breakpoint('xxl') {
      grid-row: auto;
      grid-column-start: auto;
    }
  }
}
