@import "settings";

image-cropper {
  align-items: center;
  padding: 0 !important;

  &.cropper {
    background-color: $main-white;

    .ngx-ic-move {
      border: rem(4px) solid $main-white !important;
    }

    .ngx-ic-cropper {
      outline: var(--cropper-outline-color, rgba($main-black, .5)) solid 100vw !important;

      &.ngx-ic-round {
        border-radius: 100% !important;
      }

      &::after {
        border: rem(4px) solid $main-white !important;
      }

      .ngx-ic-top,
      .ngx-ic-right,
      .ngx-ic-bottom,
      .ngx-ic-left {
        display: none !important;
      }

      .ngx-ic-square {
        border: rem(4px) solid transparent !important;
        background: transparent !important;
        width: rem(15px) !important;
        height: rem(15px) !important;
      }

      .ngx-ic-topleft {
        top: rem(-8px) !important;
        left: rem(-8px) !important;

        .ngx-ic-square {
          border-top: rem(4px) solid $main-white !important;
          border-left: rem(4px) solid $main-white !important;
        }
      }

      .ngx-ic-topright {
        right: rem(-8px) !important;
        top: rem(-8px) !important;

        .ngx-ic-square {
          border-top: rem(4px) solid $main-white !important;
          border-right: rem(4px) solid $main-white !important;
        }
      }

      .ngx-ic-bottomright {
        bottom: rem(-8px) !important;
        right: rem(-8px) !important;

        .ngx-ic-square {
          border-bottom: rem(4px) solid $main-white !important;
          border-right: rem(4px) solid $main-white !important;
        }
      }

      .ngx-ic-bottomleft {
        left: rem(-8px) !important;
        bottom: rem(-8px) !important;

        .ngx-ic-square {
          border-left: rem(4px) solid $main-white !important;
          border-bottom: rem(4px) solid $main-white !important;
        }
      }
    }
  }
}
