@import "settings";

.company-banner {
  @include adaptive-value('padding-inline', 115, 16, 1);
  @include adaptive-value('padding-block', 85, 16, 1);
  @include adaptive-value('border-radius', 70, 24, 1);
  position: relative;
  overflow: hidden;

  @include breakpoint('md') {
    min-height: rem(650px);
  }

  &__picture {
    position: absolute;
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    &::after {
      content: '';
      position: absolute;
      inset: 0;
      background: linear-gradient(272deg, rgba($main-black, 0.7) 50%, rgba($main-black, 0) 135%),
    }
  }

  &__main {
    @include adaptive-value('gap', 50, 15, 1);
    display: grid;
    align-items: flex-start;
    justify-content: flex-start;
    isolation: isolate;

    @include breakpoint('md') {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &__link {
    padding: rem(15px) rem(25px);
    border-radius: rem(50px);
    display: inline-flex;
    align-items: center;
    gap: rem(10px);

    svg {
      width: rem(20px);
      display: flex;
      aspect-ratio: 1;
    }

    &--unset {
      padding: 0;
    }
  }

  &__content {
    @include adaptive-value('gap', 40, 20, 1);
  }

  &__socials {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(rem(70px), 1fr));
    gap: rem(5px);

    a {
      display: flex;
      justify-content: center;
      padding-inline: rem(15px);
      border-radius: rem(10px);
      box-shadow: inset 0 0 0 rem(1px) $silver;
      isolation: isolate;
      position: relative;
      overflow: hidden;

      &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 0;
        border-radius: 50%;
        background-color: $silver;
        z-index: -1;
        aspect-ratio: 1;
        transition: width 404ms ease-in-out;
      }

      svg {
        width: rem(42px);
        aspect-ratio: 1;
        display: flex;
        transition: color 404ms ease-in-out;
      }

      @media (hover: hover) {
        &:hover {
          &::after {
            width: 110%;
          }

          svg {
            color: $main-black;
          }
        }
      }
    }
  }
}
