$main-white: #ffffff;
$gallery: #F0F0F0;
$alabaster: #F8F8F8;
$concrete: #F3F3F3;
$silver: #C4C4C4;
$silver-chalice: #BDBDBD;
$gray-opacity: #ffffff33;
$black-opacity: #00000033;
$gray-two: #d9d9d933;
$dove-gray: #666666;
$main-black: #000000;
$codGray: #111111;
$yellow: rgba(0, 0, 0, 0.2);
$main-gray: #6F6F6F;
$dark-gray: #9D9D9D;
$yellow: #FDD835;
$kournikova: #FEE472;
$mine: #343434;
$mine-shaft: #2D2D2D;
$mine-shaft-two: #3E3E3E;
$error: #FF6161;
$green: #21C262;
