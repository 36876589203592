@import "settings";

.info-item {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto auto;
  gap: rem(10px) 0;
  align-items: center;

  &:has(.info-item__icon) {
    gap: rem(10px) rem(8px);
  }

  &__icon {
    grid-row: 1;
    display: flex;
    width: rem(20px);
  }

  &__btn {
    flex: none;
    width: rem(25px);
  }

  &__name {
    grid-column: 2;
    font-size: rem(13px);
    line-height: 1.1;
    position: relative;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: rem(15px);
  }

  &__main {
    grid-column: 2 / -1;
    display: flex;
    flex-wrap: wrap;
    gap: rem(5px);
    font-weight: 500;
  }

  &__content {
    display: -webkit-box;
    -webkit-box-orient: vertical;

    &.is-shadow {
      -webkit-mask: linear-gradient(180deg, transparent, white 0%, white 40%, transparent);
      mask: linear-gradient(180deg, transparent, white 0%, white 40%, transparent);
      transition: -webkit-mask 404ms ease-in-out;

      &.is-open {
        -webkit-mask: linear-gradient(180deg, transparent, white 0%, white 100%, transparent);
        mask: linear-gradient(180deg, transparent, white 0%, white 100%, transparent);
      }
    }
  }
}
