@import "settings";

.hover-link {
  background: linear-gradient(0deg, currentColor, currentColor) no-repeat 100% 100%/0 1px;
  transition: background-size .35s;

  @media (hover: hover) {
    cursor: pointer;

    &:hover {
      background-position-x: left;
      background-size: 100% rem(1px);
    }
  }
}
