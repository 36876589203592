@mixin font-face($family, $style, $weight, $src) {
  $filePath: '#{$fonts-path}#{$src}/#{$src}';
  @font-face {
    font-family: $family;
    src: url('#{$filePath}.woff2') format("woff2"),
    url('#{$filePath}.woff') format('woff');
    font-style: $style;
    font-weight: $weight;
    font-display: swap;
  }
}

@mixin breakpoint($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media (min-width: map-get($breakpoints, $breakpoint)) {
      @content;
    }
  } @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Available breakpoints are: #{map-keys($breakpoints)}.";
  }
}

$maxWidth: 1920;
$maxWidthContainer: 1920;
$minContainer: 375;

@mixin adaptive-value($property, $startSize, $minSize, $type) {
  $addSize: $startSize - $minSize;

  @if $type==1 {
    #{$property}: $startSize + px;

    @media (max-width: #{$maxWidthContainer + px}) {
      #{$property}: calc(#{$minSize + px} + #{$addSize} * ((100vw - #{$minContainer + px}) / #{$maxWidthContainer - $minContainer}));
    }
  } @else if $type==2 {
    #{$property}: $startSize + px;

    @media (min-width: #{$maxWidthContainer + px}) {
      #{$property}: calc(#{$minSize + px}#{$addSize} * ((100vw - #{$minContainer + px}) / #{$maxWidth - $minContainer}));
    }
  } @else {
    #{$property}: calc(#{$minSize + px} + #{$addSize} * ((100vw - #{$minContainer + px}) / #{$maxWidth - $minContainer}));
  }
}
