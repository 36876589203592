@import "settings";

// @TODO: Have color dark-gray, yellow, green

.label-status {
  color: currentColor;
  font-size: rem(9px);
  padding: rem(3px) rem(7px);
  border-radius: 100vmax;
  display: inline-flex;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    background-color: currentColor;
    inset: 0;
    border-radius: inherit;
    opacity: .2;
  }
}

