@import "settings";

.swiper-pagination {
  display: flex;
  gap: rem(5px);
  position: absolute;
  top: calc(100% + #{rem(15px)});
  left: 50%;
  transform: translateX(-50%);

  .swiper-pagination-bullet {
    width: rem(8px);
    aspect-ratio: 1;
    box-shadow: inset 0 0 0 rem(5px) currentColor;
    border-radius: 50%;
    transition: box-shadow 404ms;

    &.swiper-pagination-bullet-active {
      box-shadow: inset 0 0 0 rem(1px) currentColor;
    }
  }
}
