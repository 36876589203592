@import "settings";

.big-btn {
  padding: rem(20px) rem(60px);
  @include adaptive-value('font-size', 22, 18, 1);
  border-radius: 100vmax;

  @include breakpoint('xl') {
    padding: rem(30px) rem(60px);
  }

  &--min-width {
    min-width: rem(360px);
  }

  &--max-width {
    width: 100%;
    max-width: rem(360px);
  }

  &--full-width {
    width: 100%;
  }

  &[disabled] {
    opacity: .5;
    cursor: not-allowed;
  }

  &__border {
    background-color: transparent;
    box-shadow: inset 0 0 0 rem(2px) currentColor;
    border-radius: 50vmax;
  }

  &--small {
    padding: rem(18px) rem(25px);
    font-size: rem(16px);
  }
}

button:not(.not-hover) {
  user-select: none;

  @media (hover: hover) {
    transition-property: opacity, box-shadow, transition;
    transition-duration: 404ms;
    transition-timing-function: ease-in-out;

    &:hover {
      opacity: .6;
    }
  }
}

.btn-small {
  background-color: $yellow;
  font-size: rem(14px);
  font-weight: 700;
  justify-content: center;
  width: 100%;
  padding: rem(10px) rem(15px);
  border-radius: 100vmax;
  white-space: nowrap;
  display: inline-flex;
  align-items: center;
  gap: rem(15px);

  &:has(svg-icon) {
    padding: rem(20px) rem(20px) rem(20px) rem(35px);

    svg-icon {
      @include adaptive-value('width', 60, 45, 1);
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      aspect-ratio: 1;

      svg {
        width: 55%;
      }
    }
  }

  &[disabled] {
    opacity: .6;
    cursor: not-allowed;
  }
}

.btn-add-vacancy {
  padding: rem(10px) rem(20px);
  color: currentColor;
  box-shadow: inset 0 0 0 rem(1px) currentColor;
  position: relative;
  border-radius: 100vmax;

  &::after {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: inherit;
    background-color: currentColor;
    opacity: .1;
  }
}

.btn-filter {
  border-radius: 50vmax;
  padding: rem(6px) rem(6px) rem(6px) rem(50px);
  font-size: rem(14px);
  line-height: 1.1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: rem(10px);
  width: 100%;

  .counter-filter {
    --tw-ring-opacity: 1;
  }

  @include breakpoint('lg') {
    padding: rem(18px) rem(25px);
  }

  &:has(svg-icon) {
    svg-icon {
      order: 1;
      aspect-ratio: 1;
      display: flex;
      width: rem(76px);
      justify-content: center;
      align-items: center;
      border-radius: 50%;

      svg {
        width: rem(40px);

        @include breakpoint('lg') {
          width: rem(25px);
        }
      }

      @include breakpoint('lg') {
        order: 0;
        width: auto;
      }
    }
  }
}

.circle-btn {
  width: 100%;
  aspect-ratio: 1;
  border-radius: 50%;

  > * {
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 404ms;

    svg {
      width: 40%;
    }
  }

  @media (hover: hover) {
    &:not(.not-hover) {
      cursor: pointer;

      &:hover {
        > * {
          transform: scale(1.2);
        }
      }
    }
  }
}

.backspace {
  --color-hover: var(--tw-ring-color);

  width: 100%;
  aspect-ratio: 1;
  display: grid;
  place-content: center;
  border-radius: 50%;

  @media (hover: hover) {
    position: relative;
    z-index: 1;

    &:after {
      content: '';
      position: absolute;
      inset: 0;
      border-radius: inherit;
      background-color: var(--color-hover);
      opacity: 0;
      transform: scale(0);
      transition-property: opacity, transform;
      transition-duration: .15s;
      transition-timing-function: cubic-bezier(0.4,0,0.2,1);
      z-index: -1;
    }

    &:hover {
      opacity: 1;

      &:after {
        transform: scale(1);
        opacity: .3;
      }
    }
  }
}

.favorite {
  width: rem(45px);
  padding: rem(10px);
  border-radius: 50%;

  svg {
    fill: transparent;
  }

  @media (hover: hover) {
    cursor: pointer;
    transition: background-color 404ms ease-in-out;

    &:hover {
      background-color: rgba($yellow, .3);
    }
  }
}
