@import "settings";

.arrow-btn {
  transition: opacity 404ms;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 50%;
  aspect-ratio: 1;

  @media (hover: hover) {
    cursor: pointer;

    &:hover {
      opacity: .7;
    }
  }

  &__icon {
    width: 40%;
    height: 50%;
    isolation: isolate;
    position: relative;

    &::after,
    &::before {
      position: absolute;
      background-color: currentColor;
      transition: all 500ms cubic-bezier(.25, 1.7, .35, .8);
      display: flex;
      z-index: -1;
      width: 60%;
      top: 50%;
      height: 12%;
    }

    &:after {
      left: 0;
      transform: rotate(35deg) translateY(-50%);
      content: "";
      border-radius: 10vmax 20vmax 20vmax 10vmax;
    }

    &::before {
      left: 45%;
      transform: rotate(-35deg) translateY(-50%);
      content: '';
      border-radius: 20vmax 10vmax 10vmax 20vmax;
    }
  }

  &.open & {
    &__icon {
      &::after,
      &::before {
        top: 40%;
      }

      &::after {
        transform-origin: center center;
        transform: rotate(-35deg);
      }

      &::before {
        transform-origin: center center;
        transform: rotate(35deg);
      }
    }
  }
}
