@import "settings";

.block-registration {
  font-weight: 500;
  font-size: rem(12px);
  padding: rem(25px) rem(45px);
  border-radius: 100vmax;
  box-shadow: inset 0 0 0 rem(1px) currentColor;
  position: relative;

  @include breakpoint('xl') {
    font-size: rem(14px);
    padding: rem(35px) rem(55px);
  }

  &__icon {
    position: absolute;
    left: rem(45px);
    top: rem(5px);
    width: rem(25px);
    aspect-ratio: 1;
    transform: translateY(-50%);

    @include breakpoint('xl') {
      left: rem(55px);
      width: rem(35px);
    }
  }
}
