@import "settings";

.ellipse-menu {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  aspect-ratio: 1;

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50vmax;
    height: 100%;
    width: 100%;
    overflow: hidden;
    position: relative;
    padding: rem(4px);
    background-color: transparent;;

    @media (hover: hover) {
      cursor: pointer;
      transition: background-color 404ms ease-in-out;

      &:hover {
        background-color: rgba($main-gray, .2);
      }
    }

    svg-icon {
      display: flex;
      flex-direction: column;
    }
  }

  @media (hover: hover) {
    cursor: pointer;
  }
}

.ellipse-menu-dropdown {
  position: absolute;
  top: calc(100% + #{rem(10px)});
  right: 0;
  font-size: rem(14px);
  font-weight: 500;
  padding-block: rem(15px);
  display: flex;
  flex-direction: column;
  gap: rem(2px);
  border-radius: rem(25px) 0 rem(25px) rem(25px);
  min-width: rem(155px);
  overflow: hidden;
  z-index: 10;
  box-shadow: rem(16px) rem(17px) rem(31px) rgba($main-black, 0.25);

  a {
    padding: rem(5px) rem(20px);
    display: flex;
    font-weight: 600;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      inset: 0;
      background-color: currentColor;
      opacity: 0;
      transition: opacity 404ms;
    }

    &:hover {
      &::after {
        opacity: .1;
      }
    }
  }
}
