@import "settings";

.quill-content {
  --color-link: #{$yellow};

  display: grid;
  align-items: flex-start;
  gap: rem(10px);

  a {
    color: var(--color-link);
    text-decoration: underline;

    @media (hover: hover) {
      transition: opacity 404ms ease-in-out;

      &:hover {
        opacity: .7;
      }
    }
  }

  em {
    font-style: italic;
  }

  ul {
    display: flex;
    flex-direction: column;
    gap: rem(5px);

    li {
      display: inline-block;
      align-items: center;
      gap: rem(10px);
      position: relative;
      padding-left: rem(10px);

      &::before {
        content: "\2022";
        font-weight: bold;
        font-size: initial;
        position: absolute;
        left: 0;
        top: 0;
      }
    }
  }

  p {
    overflow: hidden;
    text-overflow: ellipsis;
    flex: none;
    height: max-content;
  }
}
