@import "settings";

.tag {
  @include adaptive-value('font-size', 16, 14, 1);
  border-radius: rem(30px);
  padding: rem(7px) rem(12px);
  font-weight: 600;
}

.tag-icon {
  font-size: rem(12px);
  border-radius: 50vmax;
  padding: rem(10px) rem(20px) rem(10px) rem(10px);
  position: relative;
  display: flex;
  align-items: center;
  gap: rem(15px);

  &::before {
    content: '';
    width: rem(10px);
    display: flex;
    aspect-ratio: 1;
    background-color: currentColor;
    border-radius: 50%;
  }
}
