@import "settings";

.preview-profile {
  padding: rem(10px) rem(10px) rem(10px) rem(35px);
  border-radius: 50vmax;
  display: grid;
  align-items: center;
  grid-template-columns: 1fr rem(56px);
  gap: rem(10px);
  justify-content: space-between;
  text-align: left;
  box-shadow: inset 0 0 0 rem(3px) currentColor;
  background-color: currentColor;

  @include breakpoint('lg') {
    grid-template-columns: 1fr rem(70px);
  }

  &--progress {
    transition: background 404ms ease-in-out;
    background: linear-gradient(to right, $yellow 0 50%, transparent 50% 100%) calc(100% - var(--progress-profile));
    background-size: 200%;

    @include breakpoint('lg') {
      background-color: currentColor;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;

    span:not(.mode) {
      mix-blend-mode: difference;

      @include breakpoint('lg') {
        mix-blend-mode: normal;
      }
    }
  }

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    aspect-ratio: 1;
    width: 100%;
    border-radius: 50%;

    svg-icon {
      width: 40%;
      display: flex;

      svg {
        width: 100%;
        aspect-ratio: 1;
      }
    }
  }
}
