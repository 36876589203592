@import "settings";

file-upload.file-upload,
.file-preview {
  min-height: rem(110px);
  border-radius: rem(30px);
  box-shadow: inset 0 0 0 rem(1px) currentColor;
  background-color: transparent;
  padding: 0;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: box-shadow 303ms, background-color 303ms;

  .upload-input {
    inset: 0;
    transform: none;
    display: flex;
    justify-content: center;
    align-items: center;
    color: currentColor !important;
    transition: opacity 404ms ease-in-out;

    &:hover {
      opacity: .5;
    }
  }

  &.has-files {
    box-shadow: inset 0 0 0 rem(1px) $main-white;
    background-color: $main-white;
    color: $main-black;

    .upload-input {
      display: none;
    }
  }

  .file-upload-text {
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: rem(10px);

    &__title {
      font-size: rem(18px);
    }

    &__sub-title {
      font-size: rem(8px);
      font-weight: 400;
    }
  }

  .remove-file {
    position: absolute;
    cursor: pointer;
    right: rem(10px);
    top: rem(10px);
    width: rem(20px);
    aspect-ratio: 1;
    color: $main-black;
    padding: rem(5px);
  }

  .file-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-inline: rem(15px);
    text-align: center;
    overflow: hidden;

    .file-name {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 100%;
    }
  }

  .upload-list {
    width: 100%;
  }
}
