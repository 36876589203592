@import "settings";

h1 {
  font-size: rem(70px);
}

h2 {
  font-size: rem(24px);
  text-align: center;
  font-weight: 700;
  text-transform: uppercase;
}
