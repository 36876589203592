@import "settings";

.form-group,
app-date-range {
  display: flex;
  flex-direction: column;
  gap: rem(5px);
  width: 100%;
  position: relative;

  input::placeholder {
    opacity: .3;
  }

  &.required {
    --bg-color: #{$yellow};

    &--white {
      --bg-color: #{$main-white};
    }

    label:not(.not-required) {
      position: relative;

      &::after {
        content: '';
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        width: rem(8px);
        aspect-ratio: 1;
        background-color: var(--bg-color);
        border-radius: 50%;
      }
    }
  }

  textarea {
    padding: rem(20px);
  }

  app-error-message {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
  }

  &__with-icon {
    position: relative;

    input {
      padding-left: rem(60px);
    }

    svg-icon {
      position: absolute;
      height: calc(100% - #{rem(20px)});
      aspect-ratio: 1;
      left: rem(10px);
      top: 50%;
      transform: translateY(-50%);
      border-radius: 50%;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .textarea-counter {
    position: absolute;
    top: calc(100% + #{rem(4px)});
    right: rem(10px);
    font-weight: 400;
    font-size: rem(10px);
  }
}

label {
  color: currentColor;
  font-weight: 400;
  font-size: rem(12px);
  line-height: 1.1;
  opacity: .5;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  transition: color 404ms;

  &.not-opacity {
    opacity: 1;
  }
}

.container-form {
  margin: 0 auto;
  max-width: rem(350px);
  padding: rem(15px);
  width: 100%;
  height: 100%;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;

  router-outlet ~ * {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    & > * {
      padding-block: rem(15px);

      @include breakpoint('lg') {
        padding-block: rem(25px);
      }
    }
  }
}

form {
  display: flex;
  flex-direction: column;
  gap: rem(20px);

  @include breakpoint('xl') {
    gap: rem(25px);
  }
}

.form-remember {
  display: flex;
  justify-content: space-between;
  gap: rem(15px);
  align-items: center;
  width: 100%;
}

.form-navigation {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: rem(5px)
}

.form-group.error-label {
  label {
    color: $error;
  }

  .ng-invalid:not(app-multiple-select, quill-editor, app-editor):not(app-date-range):not(app-location-autocomplete):not(app-user-avatar-cropper) {
    box-shadow: inset 0 0 0 rem(1px) $error;
  }

  app-date-picker.ng-invalid .datepicker-container input,
  app-multiple-select.ng-invalid .ng-select,
  app-editor.ng-invalid,
  app-location-autocomplete .ng-select,
  app-user-avatar-cropper .cropper-input {
    box-shadow: inset 0 0 0 rem(1px) $error;
  }
}

.with-icon {
  gap: rem(8px);

  & > *:not(.icon) {
    width: calc(100% - #{rem(64px)});
  }

  .icon {
    width: auto;
    align-self: center;
    height: rem(50px);
    aspect-ratio: 1;
  }
}

.length-counter {
  position: absolute;
  right: 0;
  font-size: rem(12px);
  top: 100%;
}

[role*="alert"] {
  position: absolute;
  top: 100%;
  left: rem(15px);
  font-size: rem(10px);
  color: $error;
  font-weight: 600;
  opacity: 0;
  animation-name: opacityError;
  animation-fill-mode: forwards;
  animation-duration: 404ms;
}

@keyframes opacityError {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

