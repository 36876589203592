@import "settings";

.progress-profile {
  flex-direction: column;
  justify-content: center;
  border-radius: rem(60px);
  box-shadow: inset 0 0 0 rem(3px) $yellow;
  text-align: center;
  align-self: start;
  aspect-ratio: 1;
  width: 100%;

  &__number {
    font-size: rem(85px);
    line-height: 1;
    font-variant-numeric: tabular-nums;
  }

  &__status {
    font-size: rem(24px);
    font-weight: 500;
  }
}
