@import "settings";

.block-info-accordion {
  @include adaptive-value('padding-left', 90, 16, 1);
  @include adaptive-value('padding-right', 40, 16, 1);
  @include adaptive-value('padding-block', 40, 20, 1);
  border-radius: rem(30px);
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(rem(200px), 1fr));
  gap: rem(20px);
  align-items: flex-start;

  &--pd-block-unset {
    padding-block: 0;
  }

  &--pd-unset {
    padding: 0;
  }

  &--row {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0;
  }

  &--bg-transparent {
    background-color: rgba(60, 60, 60, .4);;
  }
}
